import { AvailableLanguages, MyDataTravelDocType } from '@crew-webui/common/consts';

import {
  AirplanemodeActive,
  ArrowBack,
  ArrowForward,
  Delete,
  ExitToApp,
  ExpandMore,
  Feedback,
  Info,
  LocalHotel,
  LocalOffer,
  PowerSettingsNew,
  Warning,
  WorkOffRounded,
} from '@material-ui/icons';

import {
  CheckInTitle, CountryIssueDefaultValues, CrewMemberAttributes, CustomerProfile, PersonalDataFieldPaths, ProfileFeature, SelectionMode,
} from 'consts';
import defaultProfileSettings from './defaultProfile';

const ewgProfileSettings: CustomerProfile = {
  ...defaultProfileSettings,
  enablings: {
    [ProfileFeature.VIEW_BLOCKMONTH_SPECIFIC_DATA]: false,
    [ProfileFeature.VIEW_LEG]: true,
    [ProfileFeature.VIEW_DEADHEAD]: true,
    [ProfileFeature.VIEW_CHECK_IN]: true,
    [ProfileFeature.PERFORM_CHECK_IN]: true,
    [ProfileFeature.VIEW_NOTIFICATIONS]: true,
    [ProfileFeature.VIEW_PAIRING]: true,
    [ProfileFeature.VIEW_SUMMARY]: true,
    [ProfileFeature.VIEW_BLOCKMONTH_SWITCH_ON_SUMMARY]: true,
    // ADVANCED
    [ProfileFeature.VIEW_HOTEL]: false,
    [ProfileFeature.PERFORM_ROLE_EDITING]: false,
    [ProfileFeature.VIEW_ROSTER_TAGS_CALENDAR_FILTER]: false,
  },
  logo: '',
  showCookieBanner: true,
  autoRefreshEnabledByDefault: true,
  showUTCTime: false,
  showLocalTime: true,
  localTimePostfix: 'LT',
  utcTimePostfix: '',
  // for Pairings
  showPairingNumber: false,
  showRank: true,
  showHomeBase: false,
  showAircraftTypeCodeOfPairing: false,
  showPairingClassification: false,
  // for Legs
  showAircraftSubType: true,
  showAircraftTypeCodeOfLeg: false,
  showRegistration: true,
  showGateInfo: false,
  // ----------------------------------------------------------------
  showRosterChanges: false,
  showBlockMonthCode: true,
  showBlockMonthInterval: false,
  calendarWeekStartMonday: false,
  showLargeSuspIcon: true,
  showGroundActivityCodeInCalendar: true,
  showCalendarAfterLogin: true,
  cacheBeforeDaysNo: 30,
  cacheAfterDaysNo: 30,
  cacheFlightInfoDaysNo: 2,
  printCacheSize: true,
  showFinNumber: false,
  showArrivalAirportOfPairing: true,
  flightDetailsEncollapsed: false,
  icons: {
    PAR: AirplanemodeActive,
    PAR_EMPTY: AirplanemodeActive,
    C_I: ArrowForward,
    LEG: AirplanemodeActive,
    HOT: LocalHotel,
    C_O: ArrowBack,
    SUSP: Warning,
    SBY: PowerSettingsNew,
    RTG: LocalOffer,
    OFF: WorkOffRounded,
  },
  buttonIcons: {
    ExpandMore,
    Info,
  },
  newsListing: {
    checkIn: {
      Icon: ExitToApp,
      color: '#60AE60',
    },
    rosterChange: {
      Icon: Warning,
      color: '#FFC901',
    },
    message: {
      Icon: Feedback,
      color: '#177FD2',
    },
    delete: {
      Icon: Delete,
    },
  },
  typeCodesCalendar: {},
  typeCodesDutyList: {
    GROUND: 'GRND',
    FPR: 'DH',
  },
  dateFormats: {
    DATE: 'dd MMM yyyy',
    SHORT_DATE: 'ddMMM',
    SHORT_DATE_SEPARATED: 'dd MMM',
    TIME: 'HH:mm',
    DATE_TIME: 'dd MMM yyyy HH:mm',
    SHORT_DATE_TIME: 'dd/MM HH:mm',
  },
  notificationConfirmationSelectionMode: SelectionMode.NONE,
  checkInTitle: CheckInTitle.SHOW_ONLY_PAIRING_NUMBER,
  disabledHotelFields: [],
  hiddenHeadersIfNoData: [],
  countryIssueDefaultValue: CountryIssueDefaultValues.NONE,
  preDefinedPhoneValue: '+',
  agreementTexts: {
    pushNotification: {
      en:
        'The push messaging feature is based on the Firebase Cloud Messaging product of Google. Google Firebase © is a third-party product. The owner of this product does not provide SLA for the service, so the service cannot be guaranteed. However, the service is offered free of charge. The third-party service is not used in case you do not select the mobile push feature.',
      fr:
        'La fonction de notification poussée se base sur le produit Firebase Cloud Messaging de Google. Google Firebase © est un produit de tiers. Le propriétaire de ce produit ne fournit aucune entente sur les niveaux de service, de sorte que ce service ne peut être garanti. Le service est toutefois offert gratuitement. Vous n’avez pas recours au service de tiers si vous ne sélectionnez pas la fonction de notification poussée.',
    },
  },
  attributesCrewMember: {
    en: {
      [CrewMemberAttributes.ADD_QUALIF]: 'Additional Qualif',
      [CrewMemberAttributes.ASSIGNED_RANK]: 'Assigned Rank',
      [CrewMemberAttributes.CONTRACT_CODE]: 'Contract',
      [CrewMemberAttributes.CREWCODE]: 'Crew Id',
      [CrewMemberAttributes.EMAIL]: 'E-mail',
      [CrewMemberAttributes.HOMEBASE]: 'HB',
      // FR translation is not needed by ACA for this
      // see comments in https://trackspace.lhsystems.com/browse/NLCCSI-4067
      [CrewMemberAttributes.PAIRING_CLASSIFICATION]: 'Pairing classification',
      // FR translation is not needed by ACA for this
      [CrewMemberAttributes.PAIRING_NUMBER]: 'Pairing number',
      [CrewMemberAttributes.PHONE]: 'Phone',
      [CrewMemberAttributes.RANK]: 'Rank',
      [CrewMemberAttributes.SENIORITY]: 'Seniority',
      [CrewMemberAttributes.SEX]: 'Gender',
    },
    fr: {
      [CrewMemberAttributes.ADD_QUALIF]: 'Qualification supplémentaire',
      [CrewMemberAttributes.ASSIGNED_RANK]: 'Rang attribué',
      [CrewMemberAttributes.CONTRACT_CODE]: 'Contrat',
      [CrewMemberAttributes.CREWCODE]: 'No de matricule',
      [CrewMemberAttributes.EMAIL]: 'E-mail',
      [CrewMemberAttributes.HOMEBASE]: 'HB',
      [CrewMemberAttributes.PHONE]: 'Téléphone',
      [CrewMemberAttributes.RANK]: 'Rang',
      [CrewMemberAttributes.SENIORITY]: 'Ancienneté',
      [CrewMemberAttributes.SEX]: 'Genre',
    },
  },
  phoneTypeCodes: ['Default', 'Cell', 'Home', 'Landline'],
  disabledPersonalDataFields: [
    PersonalDataFieldPaths.birthCountry,
    PersonalDataFieldPaths.birthDate,
    PersonalDataFieldPaths.birthPlace,
    PersonalDataFieldPaths.birthState,
  ],
  enabledTravelDocTypes: [
    MyDataTravelDocType.PASSPORT,
    MyDataTravelDocType.ALIEN_RESIDENT_NUMBER,
    MyDataTravelDocType.IDENTITY,
    MyDataTravelDocType.DRIVER_LICENCE,
  ],
  readonlyMyDataCategories: [],
  availableLanguages: [AvailableLanguages.EN, AvailableLanguages.FR],
};

export default ewgProfileSettings;
